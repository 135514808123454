import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Layout from "../layouts/layout-scroll"
import { graphql } from 'gatsby'
import Image from "gatsby-image"
import useComponentVisible from '../components/hooks/useComponentVisible'

import Weeks from '../components/ui/weeks'

export default ({data}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  return (
    <Layout>
      <Helmet htmlAttributes={{ class:'months' }} />
      <SEO title="Monthly picture"
        description={data.site.siteMetadata.descriptions.months} />
      <h1>Months shots</h1>
      <div>
        <p style={{ textAlign: 'center' }}>
            A few shots to represent moments of each year.<br />
            Sometimes it's a nice DSLR picture, other ones are simply mobile pics
        </p>
        <div className="photo-grid" >
          {data.images.edges.map((image, index) => (
            <button style={{
              background: 'transparent',
              border: 'none',
              padding: 0,
              margin: 0,
            }}
            key={image.node.childImageSharp.fluid.src}
            type="button"
            onClick={() =>
              setIsComponentVisible(!isComponentVisible)
            }
            >
            <Image
            style={{
              height: '100%',
              maxHeight: '15rem'
            }}
            fluid={image.node.childImageSharp.fluid}
            />
            </button>
          ))}
        </div>
        <div ref={ref}>
          {isComponentVisible && (
            <>
              <div style={{
                width: '100%',
                height: '100%',
                background: '#000D',
                position: 'fixed',
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1000,
              }}
              className={'lightbox'}
                >
              </div>
            </>
          )}
        </div>
      </div>
  </Layout>
  )
}

export const query = graphql`
  query PicsQuery {
    images: allFile(
      filter: { sourceInstanceName: { eq: "months" } },
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        descriptions {
          months
        }
      }
    }
  }
`
